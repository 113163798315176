import clsx from 'clsx';
import React, {FC} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

import {PopularSearch} from '../../../types/data';
import footerData from '../../../assets/data/footer.json';
import SocialButtons from '../Buttons/Socials/SocialButtons';
import type {AllNewsQueryData, News} from '../../../types/news';

import * as styles from './footer.module.scss';


const Footer: FC = () => {
  const {t} = useTranslation();
  const popularSearches: PopularSearch[] = footerData.popularSearches;

  const newsQuery: AllNewsQueryData = useStaticQuery(graphql`
    query {
      news: allFile(filter: {
        sourceInstanceName: {eq: "news"},
        extension: {eq: "mdx"}
      }) {
        ...AllNewsFragment
      }
    }
  `);

  const getMostRecentNews = (): News[] => (
    newsQuery.news.nodes
      .map((node) => node.childMdx.frontmatter)
      .sort((a, b) => (
        new Date(b.date).getTime() - new Date(a.date).getTime()
      ))
      .slice(0, 3)
  );

  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        <div className={clsx(styles.section, styles.popularSearches)}>
          <h2>{t('footer.popular_searches')}</h2>

          <ul>
            {popularSearches.map((item, key) => (
              <li key={key}>
                <Link to={item.link}>{t(item.name as any)}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={clsx(styles.section, styles.latestProducts)}>
          <h2>{t('footer.latest_products_released')}</h2>
        </div>

        <div className={clsx(styles.section, styles.recentNews)}>
          <h2>{t('footer.news')}</h2>

          <ul>
            {getMostRecentNews().map((news) => (
              <li tabIndex={0} key={news.title}>
                <Link to={`/news/${news.slug}`}>
                  <p>{t(news.title as any)}</p>
                  <span className={styles.date_badge}>{new Date(news.date).getDate()}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={styles.bottom}>
        <div>
          <div className={styles.copyright}>
            <p>{t('footer.copyright.text', {year: new Date().getFullYear()})}</p>

            <Link to="/legal/legal-notice">{t('footer.copyright.legal_notice')}</Link>
            &ensp;&ndash;&ensp;
            <Link to="/legal/cookies">{t('footer.copyright.cookies_policy')}</Link>
            &ensp;&ndash;&ensp;
            <Link to="/legal/ethics-compliance">{t('footer.copyright.ethics_compliance')}</Link>
            &ensp;&ndash;&ensp;
            <Link to="/legal/data-protection">{t('footer.copyright.data_protection')}</Link>
          </div>

          <div className={styles.socials}>
            <SocialButtons/>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default Footer;
