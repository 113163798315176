import {Collapse} from '@mui/material';
import {StaticImage} from 'gatsby-plugin-image';
import React, {useEffect, useState} from 'react';
import {Link} from 'gatsby-plugin-react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faClose} from '@fortawesome/free-solid-svg-icons';

import NavItemList from './NavItemList';
import {anchorLinks} from '../../../../types/homepage';

import * as styles from './navigation.module.scss';


const MobileNavBar = () => {
  const timeout = 350; // ms
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';

      return () => {
        setTimeout(() => document.body.style.removeProperty('overflow'), timeout);
      };
    }
  }, [open]);

  return <>
    <div className={styles.mobileNav}>
      <Collapse in={open} timeout={timeout}>
        <NavItemList isMobile closeMenu={() => setOpen(false)}/>
      </Collapse>
    </div>

    <div className={styles.mobileMenu}>
      <Link to={`/#${anchorLinks.header}`}>
        <StaticImage
          alt="Logo"
          height={32}
          quality={100}
          loading="eager"
          className={styles.logo}
          src="../../../../assets/images/logos/sr-icon-color.png"
        />
      </Link>

      <button aria-label="menu" onClick={() => setOpen(!open)}>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={open ? faClose : faBars}
          />
        </div>
      </button>
    </div>
  </>;
};


export default MobileNavBar;
