import {SupportedLangs} from '../lang';


export const adminPathnameBase = 'admin';

export const adminPathsBase = [
  ...Object.values(SupportedLangs).map((lang) => `/${lang}/${adminPathnameBase}`),
  `/${adminPathnameBase}`,
];

export const adminNewsPreviewParam = 'preview';
