// extracted by mini-css-extract-plugin
export var adminLangSelect = "navigation-module--adminLangSelect--c13b1";
export var adminLangSelectWrapper = "navigation-module--adminLangSelectWrapper--7fad1";
export var adminNav = "navigation-module--adminNav--fdd2e";
export var adminSignOut = "navigation-module--adminSignOut--a9b37";
export var colored = "navigation-module--colored--5cfef";
export var desktopNav = "navigation-module--desktopNav--b604b";
export var dropdown = "navigation-module--dropdown--c0dc5";
export var dropdownItem = "navigation-module--dropdownItem--39e2e";
export var icon = "navigation-module--icon--4024e";
export var iconWrapper = "navigation-module--iconWrapper--48f4d";
export var logo = "navigation-module--logo--75514";
export var mobileMenu = "navigation-module--mobileMenu--28a72";
export var mobileNav = "navigation-module--mobileNav--c549d";
export var navItem = "navigation-module--navItem--67d6d";
export var navItemLeft = "navigation-module--navItemLeft--176ec";
export var navItemsUp = "navigation-module--navItemsUp--45552";
export var navbar = "navigation-module--navbar--ec96d";
export var sticky = "navigation-module--sticky--3c866";
export var title = "navigation-module--title--ad90a";