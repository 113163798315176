import {ONETRUST_DATA_DOMAIN_SCRIPT} from '../types/cookies';


export const injectOneTrustScripts = (onLoad: () => void): void => {
  const script = document.createElement('script');
  script.innerHTML = `function OptanonWrapper() {}`;

  const onetrustScript = document.createElement('script');
  onetrustScript.type = 'text/javascript';
  onetrustScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  onetrustScript.setAttribute('data-domain-script', ONETRUST_DATA_DOMAIN_SCRIPT);
  onetrustScript.setAttribute('charset', 'UTF-8');

  document.head.appendChild(script);
  document.head.appendChild(onetrustScript);
  onetrustScript.onload = onLoad;
};


export const getOneTrustConsentedGroups = (): string[] => {
  const activeGroups = window.OnetrustActiveGroups;
  return activeGroups ? activeGroups.split(',').filter(v => !!v) : [];
};


export const onOneTrustConsentChanged = (callback: () => void): void => {
  window.OneTrust?.OnConsentChanged(callback);
};


export const getOneTrustGaCategory = (): string => {
  const googleConsent = window.OneTrust?.GetDomainData().GoogleConsent as Record<string, unknown>;
  return googleConsent.GCAnalyticsStorage as string;
};
