import {StaticImage} from 'gatsby-plugin-image';
import React, {FC, useEffect, useState} from 'react';
import {MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView} from 'mdbreact';

import * as styles from './carousel.module.scss';


const Carousel: FC = () => {
  const nbOfImages = 4;
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count === (nbOfImages - 1)) setCount(0);
    const timer = setTimeout(() => setCount(count + 1), 5000);
    return () => clearTimeout(timer);
  }, [count]);

  return (
    <MDBCarousel
      slide
      activeItem={1}
      length={nbOfImages}
      showControls={false}
      className={styles.carousel}
    >
      <MDBCarouselInner className={styles.items}>
        <MDBCarouselItem itemId={1} className={styles.item}>
          <MDBView>
            <StaticImage
              alt="Biscuit"
              loading="eager"
              src="../../../../assets/images/carousel/biscuit.webp"
            />
          </MDBView>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={2} className={styles.item}>
          <MDBView>
            <StaticImage
              alt="Ice cream"
              loading="eager"
              src="../../../../assets/images/carousel/ice-cream.webp"
            />
          </MDBView>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={3} className={styles.item}>
          <MDBView>
            <StaticImage
              alt="Sauce"
              loading="eager"
              src="../../../../assets/images/carousel/sauce.webp"
            />
          </MDBView>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={4} className={styles.item}>
          <MDBView>
            <StaticImage
              alt="Soda"
              loading="eager"
              src="../../../../assets/images/carousel/soda.webp"
            />
          </MDBView>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
  );
};


export default Carousel;
