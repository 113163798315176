import React, {FC} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';

import * as styles from './socialButtons.module.scss';


const socials = [
  {icon: faFacebookF, link: 'https://www.facebook.com/SethnessRoquette', aria:'Facebook page of Sethness Roquette'},
  {icon: faInstagram, link: 'https://instagram.com/sethnessroquette', aria:'Instagram page of Sethness Roquette'},
  {icon: faLinkedinIn, link: 'https://www.linkedin.com/company/sethness-roquette', aria:'Linkedin page of Sethness Roquette'},
  {icon: faYoutube, link: 'https://www.youtube.com/channel/UCxiWrOdYseE1XE91-DtYXUA', aria:'Youtube channel of Sethness Roquette'},
  {icon: faTwitter, link: 'https://twitter.com/sethnessroquett', aria:'Twitter account of Sethness Roquette'},
];


const SocialButtons: FC = () => {
  return (
    <>
      {socials.map((item) => (
        <a
          target="_blank"
          key={item.link}
          href={item.link}
          className={styles.btn}
          aria-label={item.aria}
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={item.icon} size="xl"/>
        </a>
      ))}
    </>
  );
};


export default SocialButtons;
