import clsx from 'clsx';
import {StaticImage} from 'gatsby-plugin-image';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import React, {DetailedHTMLProps, FC, HTMLAttributes, useState} from 'react';

import {SupportedLangs} from '../../../../types/lang';

import * as styles from './flagButtons.module.scss';


type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  showSelectedLangOnly?: boolean;
};


const FlagButtons: FC<Props> = ({showSelectedLangOnly, ...props}) => {
  const {language, originalPath} = useI18next();
  const [hideFlags, setHideFlags] = useState(true);

  const toggleHideFlags = () => setHideFlags(!hideFlags);
  const isCurrentLang = (lang: SupportedLangs) => language === lang;
  const getFlagClass = (lang: SupportedLangs) => isCurrentLang(lang) ? styles.firstFlag : (hideFlags && styles.hidden);

  const getFlagImage = (lang: SupportedLangs) => {
    switch (lang) {
      case SupportedLangs.EN:
        return <StaticImage alt="en" loading="eager" src="../../../../assets/images/flags/en.svg"/>;
      case SupportedLangs.FR:
        return <StaticImage alt="fr" loading="eager" src="../../../../assets/images/flags/fr.svg"/>;
      case SupportedLangs.CN:
        return <StaticImage alt="cn" loading="eager" src="../../../../assets/images/flags/cn.svg"/>;
      case SupportedLangs.ES:
        return <StaticImage alt="es" loading="eager" src="../../../../assets/images/flags/es.svg"/>;
    }
  };


  return (
    <div
      {...props}
      className={clsx(styles.flags, props.className)}
    >
      {Object.values(SupportedLangs).map((lang) => (
        <Link
          key={lang}
          language={lang}
          to={originalPath}
          onClick={toggleHideFlags}
          className={clsx(styles.flag, getFlagClass(lang), showSelectedLangOnly && styles.showSelectedLangOnly)}
        >
          {getFlagImage(lang)}
        </Link>
      ))}
    </div>
  );
};


export default FlagButtons;
