import clsx from 'clsx';
import {Link} from 'gatsby-plugin-react-i18next';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import React, {ChangeEvent, FocusEvent, useEffect, useMemo, useRef, useState} from 'react';

import {SearchResult} from '../../../../types/data';
import searchResults from '../../../../assets/data/searchBar.json';

import * as styles from './searchBar.module.scss';


const SearchBar = () => {
  const {t} = useTranslation();
  const searchArrowRef = useRef<HTMLButtonElement | null>(null);
  const searchResultsRef = useRef<HTMLUListElement | null>(null);
  const searchData = useMemo(() => Object.values(searchResults) as SearchResult[], [t]);

  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(searchData);

  const searchQuery = (query: string): SearchResult[] => {
    if (!query) return searchData;
    return searchData.filter((item) => item.label.toLowerCase().includes(query.toLowerCase()));
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);

  const handleBlurSearchBar = (event: FocusEvent<HTMLElement>) => {
    const target = event.relatedTarget;
    if (target instanceof HTMLElement) {
      if (searchArrowRef.current?.contains(target)) return;
      if (searchResultsRef.current?.contains(target)) return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (!query) setIsOpen(false);
    if (!isOpen && !!query) setIsOpen(true);
    setData(searchQuery(query));
  }, [query]);

  return (
    <div
      onBlur={handleBlurSearchBar}
      className={clsx(styles.searchBar, isOpen && styles.isOpen)}
    >
      <input
        onChange={handleInput}
        onFocus={() => setIsOpen(!!query)}
        placeholder={t('home.searchBarPlaceholder')}
      />

      <button
        ref={searchArrowRef}
        className={styles.button}
        onClick={() => setIsOpen(!isOpen)}
        aria-label={t('home.searchBarPlaceholder')}
      >
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown}/>
      </button>

      {isOpen && (
        <ul
          tabIndex={0}
          ref={searchResultsRef}
        >
          {data.length > 0 ? (
            data.map((item, index) => (
              <li key={index}>
                <Link to={item.link}>{t(item.label as any)}</Link>
              </li>
            ))
          ) : (
            <li>
              <span>{t('home.searchBarNoResult')}</span>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};


export default SearchBar;
