import {useEffect} from 'react';
import {useLocation} from '@reach/router';


const ScrollToTopOnMount = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    if (!window.location.hash) window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


export default ScrollToTopOnMount;
