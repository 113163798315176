import React, {FC} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Button, useAuthenticator} from '@aws-amplify/ui-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons';

import NavItem from './NavItem';
import FlagButtons from '../../Buttons/Flags/FlagButtons';
import {adminPathnameBase} from '../../../../types/admin';

import * as styles from './navigation.module.scss';


const AdminPanelNavItemList: FC = () => {
  const {t} = useTranslation();
  const {signOut, route} = useAuthenticator();

  return <>
    <div className={styles.adminLangSelectWrapper}>
      <FlagButtons showSelectedLangOnly className={styles.adminLangSelect} />
    </div>

    <ul className={styles.navbar}>
      <NavItem
        link={`/${adminPathnameBase}`}
        title={<>
          <StaticImage
            alt="Logo"
            height={50}
            quality={100}
            loading="eager"
            src="../../../../assets/images/logos/sr-icon-color.png"
          />
          &ensp;
          {t('admin.menu.home')}
        </>}
      />

      <NavItem
        link={`/${adminPathnameBase}/news`}
        title={t('admin.menu.news')}
      />
    </ul>

    <Button
      tabIndex={0}
      variation="link"
      onClick={signOut}
      isLoading={route === 'signOut'}
      className={styles.adminSignOut}
    >
      <FontAwesomeIcon icon={faArrowRightFromBracket} />
    </Button>
  </>;
};


export default AdminPanelNavItemList;
