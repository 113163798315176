import {useLocation} from '@reach/router';
import React, {FC, ReactNode} from 'react';
import {ThemeProvider} from '@mui/material';
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import NavBar from './Header/NavBar/NavBar';
import {indexPaths} from '../../types/homepage';
import {adminPathsBase} from '../../types/admin';
import {getMuiTheme} from '../../styles/mui.theme';
import OneTrust from '../Shared/OneTrust/OneTrust';
import NavItemList from './Header/NavBar/NavItemList';
import MobileNavBar from './Header/NavBar/MobileNavBar';
import {LayoutContext} from '../../contexts/LayoutContext';
import ScrollToTopOnMount from '../Shared/ScrollToTop/ScrollToTop';
import AdminPanelNavItemList from './Header/NavBar/AdminPanelNavItemList';

import * as styles from './layout.module.scss';


type Props = {
  children: ReactNode,
};


const AdminPageContent: FC<Props> = ({children: pageComponent}) => {
  const {authStatus} = useAuthenticator();
  if (authStatus === 'configuring') return null;

  return <>
    {authStatus === 'authenticated' && (
      <NavBar isAdminPanel navItemList={<AdminPanelNavItemList/>}/>
    )}
    {pageComponent}
  </>;
};


const Layout: FC<Props> = ({children: pageComponent}) => {
  const location = useLocation();
  const isAdminPanel = adminPathsBase.some((path) => location.pathname.startsWith(path));

  const navBars = <><MobileNavBar/><NavBar navItemList={<NavItemList/>}/></>;
  const userHeaderComponent = !indexPaths.includes(location.pathname) ? navBars : <><Header/>{navBars}</>;

  return (
    <Authenticator.Provider>
      <LayoutContext.Provider>
        <ThemeProvider theme={getMuiTheme()}>
          <ScrollToTopOnMount/>

          <main className={styles.layout}>
            {isAdminPanel ? (
              <AdminPageContent>
                {pageComponent}
              </AdminPageContent>
            ) : (
              <>
                {userHeaderComponent}
                {pageComponent}
                <Footer/>
              </>
            )}
          </main>

          {isAdminPanel ? null : <OneTrust/>}
        </ThemeProvider>
      </LayoutContext.Provider>
    </Authenticator.Provider>
  );
};


export default Layout;
