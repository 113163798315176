import {createTheme} from '@mui/material';


// Global Material-UI theme value overrides
export const getMuiTheme = () => createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
        },

        icon: {
          display: 'flex',
          marginRight: '1rem',
          alignItems: 'center',
        },
      },
    },
  },

  palette: {
    primary: {
      main: 'rgb(208, 120, 48)',
    },
  },

  typography: {
    fontFamily: 'inherit',
  }
});
