import clsx from 'clsx';
import React, {FC, ReactElement, useCallback, useEffect, useState} from 'react';

import {getScrollbarWidth} from '../../../../utils/scrollbar';
import {useWindowSize} from '../../../../hooks/use-window-size';
import {useLayoutContext} from '../../../../contexts/LayoutContext';

import * as styles from './navigation.module.scss';


type Props = {
  isAdminPanel?: boolean,
  navItemList: ReactElement,
};


const NavBar: FC<Props> = ({navItemList, isAdminPanel}) => {
  const [windowWidth] = useWindowSize();
  const {headerRef} = useLayoutContext();
  const [sticky, setSticky] = useState(false);
  const [navItemsUp, setNavItemsUp] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const scrollResizeCallback = useCallback((e?: Event) => {
    // 768px = $tablet-landscape-breakpoint in '/src/styles/variables.scss'
    if (!isAdminPanel && windowWidth <= 768) {
      return document.documentElement.style.scrollPaddingTop = '0';
    }

    const header = headerRef.current;
    if (header && header.parentElement) {
      Array.from(header.querySelectorAll(`.${styles.navItem}`)).map((item) => {
        const dropdown = item.querySelector(`.${styles.dropdown}`);

        if (dropdown) {
          const {left} = item.getBoundingClientRect();
          const {width} = dropdown.getBoundingClientRect();
          const siteWidth = windowWidth - getScrollbarWidth();

          if (!e || e.type === 'resize') {
            if (width > (siteWidth - left)) {
              dropdown.classList.add(styles.navItemLeft);
            } else {
              dropdown.classList.remove(styles.navItemLeft);
            }
          }
        }
      });

      const maxDropdownHeight = Math.max(
        ...Array.from(header.querySelectorAll<HTMLElement>(`.${styles.dropdown}`)).map((el) => el.offsetHeight)
      );

      setHeaderHeight(header.offsetHeight);
      setSticky(header.parentElement.offsetTop - window.scrollY <= 0);
      setNavItemsUp(header.parentElement.offsetTop - window.scrollY > maxDropdownHeight);
      document.documentElement.style.scrollPaddingTop = `${header.offsetHeight - 1}px`; // Anchor links offset
    }
  }, [windowWidth]);

  useEffect(() => {
    scrollResizeCallback();

    window.addEventListener('scroll', scrollResizeCallback);
    window.addEventListener('resize', scrollResizeCallback);

    return () => {
      window.removeEventListener('resize', scrollResizeCallback);
      window.removeEventListener('scroll', scrollResizeCallback);
    };
  }, [scrollResizeCallback]);

  return (
    <div
      style={{height: headerHeight || 'auto'}}
      className={clsx(styles.desktopNav, isAdminPanel && styles.adminNav)}
    >
      <header
        ref={headerRef}
        className={clsx(sticky && styles.sticky, navItemsUp && !isAdminPanel && styles.navItemsUp)}
      >
        {navItemList}
      </header>
    </div>
  );
};


export default NavBar;
