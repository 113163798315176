import {SupportedLangs} from './lang';


export const anchorLinks = {
  header: 'header',
  caramelApplication: 'caramelApplication',
  ourCaramel: 'ourCaramel',
  facts: 'facts',
  documentation: 'documentation',
  news: 'news',
  aboutUs: 'about_us',
  findUs: 'find_us',
  contact: 'contact',
} as const;

export const indexPaths = [...Object.values(SupportedLangs).map((lang) => `/${lang}/`), '/'];
