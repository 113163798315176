// extracted by mini-css-extract-plugin
export var bottom = "footer-module--bottom--d5ff5";
export var copyright = "footer-module--copyright--a5e45";
export var date_badge = "footer-module--date_badge--6fa44";
export var footer = "footer-module--footer--34771";
export var latestProducts = "footer-module--latestProducts--cc839";
export var popularSearches = "footer-module--popularSearches--9ceb1";
export var recentNews = "footer-module--recentNews--ab0d7";
export var section = "footer-module--section--529ca";
export var socials = "footer-module--socials--49615";
export var top = "footer-module--top--7a81f";