import React, {FC} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import NavItem from './NavItem';
import {anchorLinks} from '../../../../types/homepage';
import factsData from '../../../../assets/data/facts.json';

import * as styles from './navigation.module.scss';


type Props = {
  isMobile?: boolean,
  closeMenu?: () => void,
};


const NavItemList: FC<Props> = ({isMobile, closeMenu}) => {
  const {t} = useTranslation();

  const LetsGetInTouchItem = (
    <NavItem
      colored
      closeMenu={closeMenu}
      link={`/#${anchorLinks.contact}`}
      title={t('about_us.lets_talk.title')}
    />
  );

  return (
    <ul className={styles.navbar}>
      {isMobile ? LetsGetInTouchItem : (
        <NavItem
          link={`/#${anchorLinks.header}`}
          closeMenu={closeMenu}
          title={<StaticImage
            alt="Logo"
            height={50}
            quality={100}
            loading="eager"
            src="../../../../assets/images/logos/sr-icon-color.png"
          />}
        />
      )}

      <NavItem
        link={`/#${anchorLinks.caramelApplication}`}
        title={t('menu.your_application.name')}
        closeMenu={closeMenu}
        dropdownItems={[
          {
            link: '/application/soft-drink',
            title: t('menu.your_application.soft_drink'),
          },
          {
            link: '/application/brewery',
            title: t('menu.your_application.brewery'),
          },
          {
            link: '/application/spirits',
            title: t('menu.your_application.spirits'),
          },
          {
            link: '/application/savoury',
            title: t('menu.your_application.savoury'),
          },
          {
            link: '/application/baking',
            title: t('menu.your_application.baking'),
          },
          {
            link: '/application/confectionery',
            title: t('menu.your_application.confectionery'),
          },
          {
            link: '/application/dairy',
            title: t('menu.your_application.dairy'),
          },
          {
            link: '/application/flavors',
            title: t('menu.your_application.flavors'),
          },
          {
            link: '/application/pet-food',
            title: t('menu.your_application.pet_food'),
          },
        ]}
      />

      <NavItem
        link={`/#${anchorLinks.ourCaramel}`}
        title={t('menu.our_caramel.name')}
        closeMenu={closeMenu}
        dropdownItems={[
          {
            link: '/caramel/caramel-color',
            title: t('menu.our_caramel.caramel_colors'),
          },
          {
            link: '/caramel/caramelized-sugar-syrups-burnt-sugar',
            title: t('menu.our_caramel.caramelized_sugar'),
          },
          {
            link: '/caramel/caramel-ingredients',
            title: t('menu.our_caramel.caramel_ingredients'),
          },
          {
            link: '/caramel/specialty-caramels',
            title: t('menu.our_caramel.specialty_caramel'),
          },
        ]}
      />

      <NavItem
        link={`/#${anchorLinks.facts}`}
        title={t('menu.facts.name')}
        closeMenu={closeMenu}
        dropdownItems={[
          {
            link: `/#${anchorLinks.facts}`,
            title: t('menu.facts.definitions'),
            modal: 'definition' satisfies keyof typeof factsData,
          },
          {
            link: `/#${anchorLinks.facts}`,
            title: t('menu.facts.strength_hue'),
            modal: 'strength_&_hue' satisfies keyof typeof factsData,
          },
          {
            link: `/#${anchorLinks.facts}`,
            title: t('menu.facts.properties'),
            modal: 'properties' satisfies keyof typeof factsData,
          },
          {
            link: `/#${anchorLinks.facts}`,
            title: t('menu.facts.labelling'),
            modal: 'labelling' satisfies keyof typeof factsData,
          },
          {
            link: 'https://www.caramelfacts.org/Safety.html',
            title: t('menu.facts.safety'),
          },
          {
            link: `/#${anchorLinks.facts}`,
            title: t('menu.facts.packagings'),
            modal: 'packaging' satisfies keyof typeof factsData,
          },
          {
            link: '/faq',
            title: t('menu.facts.FAQ'),
          },
        ]}
      />

      <NavItem
        link={`/#${anchorLinks.documentation}`}
        title={t('documentation.title')}
        closeMenu={closeMenu}
        dropdownItems={[
          {
            link: '/documentation/product-documentation',
            title: t('documentation.product_doc.name'),
          },
          {
            link: '/documentation/statements-certificates',
            title: t('documentation.statements_certif.name'),
          },
        ]}
      />

      <NavItem
        link={`/#${anchorLinks.news}`}
        title={t('menu.news')}
        closeMenu={closeMenu}
      />

      <NavItem
        link={`/#${anchorLinks.aboutUs}`}
        title={t('menu.about_us.name')}
        closeMenu={closeMenu}
        dropdownItems={[
          {
            link: '/about-us/sethness-roquette',
            title: t('sethness_roquette'),
          },
          {
            link: '/about-us/expertise',
            title: t('menu.about_us.expertise'),
          },
          {
            link: `/#${anchorLinks.findUs}`,
            title: t('about_us.find_us.title'),
          },
          {
            link: '/about-us/join-us',
            title: t('menu.about_us.join_us'),
          },          {
            link: '/legal/ethics-compliance',
            title: t('menu.about_us.ethics_compliance'),
          },
        ]}
      />

      {isMobile ? null : LetsGetInTouchItem}
    </ul>
  );
};


export default NavItemList;
