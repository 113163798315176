export enum SupportedLangs {
  EN = 'en',
  FR = 'fr',
  ES = 'es',
  CN = 'cn',
};

export enum SupportedRegions {
  EU = 'EU',
  NA = 'NA',
  CSA = 'CSA',
  MEA = 'MEA',
  SEA = 'SEA',
  CH = 'CH',
  IN = 'IN',
  OC = 'OC',
};

export const defaultLang = SupportedLangs.EN;

export const i18nSeparator = '.';
