import clsx from 'clsx';
import {useLocation} from '@reach/router';
import {Link} from 'gatsby-plugin-react-i18next';
import React, {FC, MouseEvent, ReactElement} from 'react';

import {indexPaths} from '../../../../types/homepage';

import * as styles from './navigation.module.scss';


type Item = {
  link: string,
  modal?: string,
  closeMenu?: () => void,
  title: string | ReactElement,
};

export type NavItemProps = Item & {
  colored?: boolean,
  dropdownItems?: Item[],
};


const NavItem: FC<NavItemProps> = ({link, title, colored, dropdownItems, closeMenu}) => {
  const location = useLocation();
  const itemClass = clsx(styles.navItem, colored && styles.colored);

  const handleNavItemClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (
      window.matchMedia('(hover: none)').matches &&
      !indexPaths.includes(location.pathname) &&
      !!dropdownItems
    ) {
      // Do not navigate to the homepage anchor link when clicking on the item name
      // if the user is using a touch device and is not already on the homepage
      // otherwise the user would not be able to open the item's dropdown.
      e.preventDefault();
    }

    closeMenu?.();
  };

  return (
    <li className={itemClass}>
      <Link
        to={link}
        className={styles.title}
        onClick={handleNavItemClick}
      >
        {title}
      </Link>

      {dropdownItems ? (
        // the 'nav-dropdown' string class is used by the navbar component
        // to determine if the dropdown should be displayed above or below
        <ul className={styles.dropdown}>
          {dropdownItems.map((item, index) => (
            // Some items have the same link
            // So we need to use the map index as the key
            <li key={index}>
              {item.link.startsWith('http') ? (
                <a
                  tabIndex={-1}
                  target="_blank"
                  href={item.link}
                  onClick={closeMenu}
                  rel="noopener noreferrer"
                  className={styles.dropdownItem}
                >
                  {item.title}
                </a>
              ) : (
                <Link
                  tabIndex={-1}
                  to={item.link}
                  onClick={closeMenu}
                  className={styles.dropdownItem}
                  state={item.modal ? {name: item.modal} : undefined}
                >
                  {item.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};


export default NavItem;