import {useEffect, useState} from 'react';

import {getOneTrustConsentedGroups, injectOneTrustScripts, onOneTrustConsentChanged} from '../services/onetrust';


export const useOneTrust = () => {
  const [consentedGroups, setConsentedGroups] = useState<string[]>([]);
  const [scriptsInjected, setScriptsInjected] = useState(false);

  const refreshConsentedGroups = () => {
    setConsentedGroups(getOneTrustConsentedGroups());
  };

  const listenConsentChangedEvent = () => {
    onOneTrustConsentChanged(refreshConsentedGroups);
  };

  useEffect(() => {
    if (!window.OneTrust) {
      injectOneTrustScripts(() => {
        const interceptor = (value: any) => {
          Object.defineProperty(window, 'OneTrust', {value});
          setScriptsInjected(true);
        };

        Object.defineProperty(window, 'OneTrust', {
          configurable: true,
          set: interceptor,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (scriptsInjected) {
      refreshConsentedGroups();
      listenConsentChangedEvent();
    }
  }, [scriptsInjected]);

  return {
    consentedGroups,
    scriptsInjected,
  };
};
