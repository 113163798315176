import {useEffect} from 'react';
import {useLocation} from '@reach/router';
import {initializeAndTrack} from 'gatsby-plugin-gdpr-cookies';

import {useOneTrust} from '../../../hooks/use-onetrust';
import {getOneTrustGaCategory} from '../../../services/onetrust';
import {GA_COOKIE_NAME, GA_DISABLE_FLAG} from '../../../types/cookies';


const OneTrust = () => {

    const isOneTrustBot = typeof window !== 'undefined' ? /OneTrust/gi.test(navigator.userAgent) : false;
    const location = useLocation();
    const {consentedGroups, scriptsInjected} = useOneTrust();

    useEffect(() => {
        document.cookie = `${GA_COOKIE_NAME}=true;`;
        if (!isOneTrustBot) {
            if (scriptsInjected) {
                const gaCategory = getOneTrustGaCategory();
                const gaAccepted = consentedGroups.includes(gaCategory);
                (window as any)[GA_DISABLE_FLAG] = !gaAccepted;
                if (gaAccepted) {
                    initializeAndTrack(location);
                }
            }
        } else {
            // Note : must add specific logic for onetrust bot to detect GA cookie
            (window as any)[GA_DISABLE_FLAG] = false;
            initializeAndTrack(location);
        }
    }, [scriptsInjected, consentedGroups, isOneTrustBot]);

    return null;
};


export default OneTrust;
