import React, {createContext, FC, MutableRefObject, ReactNode, useContext, useRef, useState} from 'react';


type InitialState = {
  headerRef: MutableRefObject<HTMLElement | null>,
};

const initialState: InitialState = {
  headerRef: {current: null},
};

export const LayoutContextInstance = createContext(initialState);
export const useLayoutContext = () => useContext(LayoutContextInstance);


const LayoutContextProvider: FC<{children: ReactNode}> = ({children}) => {
  const headerRef = useRef<HTMLElement | null>(null);

  return (
    <LayoutContextInstance.Provider value={{headerRef}}>
      {children}
    </LayoutContextInstance.Provider>
  );
};


export const LayoutContext = {
  Provider: LayoutContextProvider,
};
