import React, {useContext} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {I18nextContext} from 'gatsby-plugin-react-i18next';

import Carousel from './Carousel/Carousel';
import SearchBar from './SearchBar/SearchBar';
import {SupportedLangs} from '../../../types/lang';
import {anchorLinks} from '../../../types/homepage';
import FlagButtons from '../Buttons/Flags/FlagButtons';
import SocialButtons from '../Buttons/Socials/SocialButtons';

import * as styles from './header.module.scss';


const Header = () => {
  const currentLanguage =  useContext(I18nextContext)

  return (
    <div className={styles.header} id={anchorLinks.header}>
      <Carousel/>

      <div className={styles.buttonsRow}>
        <div className={styles.flags}>
          <FlagButtons/>
        </div>

        <div className={styles.socials}>
          <SocialButtons/>
        </div>
      </div>

      <div className={styles.overlay}>
        {currentLanguage.language === SupportedLangs.CN ? (
          <StaticImage src="../../../assets/images/logos/sr-text-white-cn.png" alt="logo"/>
        ) : (
          <StaticImage src="../../../assets/images/logos/sr-text-white-en.png" alt="logo"/>
        )}

        <SearchBar/>
      </div>
    </div>
  );
};


export default Header;
