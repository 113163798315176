import {Amplify} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

//@ts-ignore
import awsConfig from './src/aws-exports';

import './src/styles/global.scss';


// Configure Amplify for admin panel authentication.
Amplify.configure(awsConfig);


export const shouldUpdateScroll = ({prevRouterProps, routerProps, getSavedScrollPosition}: any): boolean => {
  // Only scroll to top if the user is not navigating back or refreshing the page, if the pathname is new, and if the hash is empty.
  if (
    prevRouterProps?.location &&
    routerProps.location.hash === '' &&
    routerProps.location.action === 'PUSH' &&
    prevRouterProps.location.pathname !== routerProps.location.pathname
  ) {
    requestAnimationFrame(() => window.scrollTo(0, 0));
    return false;
  }

  const [x, y] = getSavedScrollPosition(routerProps.location);

  // If the user navigates back to a page they've already visited or refreshes the page, scroll to the previous position.
  const isRefresh = !prevRouterProps && !routerProps.location.action;
  const isBack = !!prevRouterProps && !routerProps.location.action;
  const canRestoreScroll = isBack || isRefresh;
  const hasRestorableScroll = x > 0 || y > 0;

  if (canRestoreScroll && hasRestorableScroll) {
    requestAnimationFrame(() => window.scrollTo(x, y));
  }

  return true;
};
